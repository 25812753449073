import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import useStoreById from '../hooks/useStoreById';
import { internalTracking } from '../api';
import { useQuery } from "react-query";

import '@styles/MarkerMap.scss';

const MarkerMap = ({img_src, opacity, storeId, setDataStore, categoryValue, setCategoryValue,lat,lng, setLoading2}) => {
    const [clickedStore, setClickedStore] = useState(null);

    const getStoreUseQuery = async ({queryKey}) => {
        if(queryKey[1]){
            setLoading2(true)
            const { store } =  await useStoreById(queryKey[1], categoryValue)
            setLoading2(false)
            return store;
        }else{
            return {}
        }
    }

    const { data, status } = useQuery({
        queryKey: ['storeId', clickedStore],
        queryFn: getStoreUseQuery,
        staleTime: 5 * (60 * 1000),
        cacheTime: 7 * (60 * 1000),
    });

    useEffect(() => {
        if (status === "success" && data && clickedStore) {
            data.lat = lat
            data.lng = lng
            setDataStore(data);
            setClickedStore(null);
        }
    }, [status, data, clickedStore]);

	const handleClick = async() => {
        internalTracking({ page: 'search', lat, lng, storeId, view:'map' })
        setClickedStore(storeId)
        setCategoryValue(categoryValue)
        $('#response-detail-modal').modal('show');
        $('#response-detail-modal .modal-content').animate({ scrollTop: 0 }, 'fast');
    };
    return (
        <div  className="markerMap" style={{opacity: opacity}} onClick={handleClick}>
            <img src={img_src} />
        </div>
    )
}

export default MarkerMap;